import React, {Component} from 'react'
import { Image, Header, Icon } from 'semantic-ui-react'

function HeaderComponent(props) {
    return <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    <div style={{display: 'flex', alignItems: 'center'}}>
        <Image
        src='/images/sbslogo.png'
        size='small'
        />
        <div>
        <Header style={{color: 'white'}} as='h1'>Simple Beer Service </Header>
        <Header style={{color: 'white'}} as='h4'>Beer=Good. Streaming Beer=Better.</Header>
        </div>
    </div>
    <div style={{display: 'flex', alignItems: 'center', gap: '2.5rem', paddingTop: '1rem', paddingBottom: '1rem', paddingRight: '2rem'}}>
        <a style={{color: 'white'}} href='https://medium.com/aws-activate-startup-blog/internet-of-beer-introducing-simple-beer-service-63c987037e9e'>
            <Icon size= 'big' name='aws'/>Our Story </a>
        <a style={{color: 'white'}} href='https://github.com/awslabs/simplebeerservice'>
            <Icon size= 'big' name='github'/> Build Your Own 
        </a>
    </div>
    </div>
}

export default HeaderComponent