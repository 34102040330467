/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTap = /* GraphQL */ `
  query GetTap($id: ID!) {
    getTap(id: $id) {
      id
      createdAt
      TapBeerId
      TapKegId
      updatedAt
    }
  }
`;
export const listTaps = /* GraphQL */ `
  query ListTaps(
    $filter: ModelTapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        TapBeerId
        TapKegId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBeer = /* GraphQL */ `
  query GetBeer($id: ID!) {
    getBeer(id: $id) {
      id
      brewery
      createdAt
      logo
      name
      updatedAt
    }
  }
`;
export const listBeers = /* GraphQL */ `
  query ListBeers(
    $filter: ModelBeerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brewery
        createdAt
        logo
        name
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKegerator = /* GraphQL */ `
  query GetKegerator($id: ID!) {
    getKegerator(id: $id) {
      id
      calibration
      createdAt
      custodian
      kegeratorId
      kegLocationId
      name
      updatedAt
    }
  }
`;
export const listKegerators = /* GraphQL */ `
  query ListKegerators(
    $filter: ModelKegeratorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKegerators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        calibration
        createdAt
        custodian
        kegeratorId
        kegLocationId
        name
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      createdAt
      lat
      long
      name
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        lat
        long
        name
        updatedAt
      }
      nextToken
    }
  }
`;
