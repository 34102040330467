import React from 'react'

import TapCard from '../components/TapCard'
import ContentContainer from '../components/ContentContainer'
import _ from 'lodash'

function Body(props) {
    //beers and taps are an array of JSON Objects, passed in from the Dashboard.js file and Taps() function
    const taps = props.taps
    const beers = props.beers
    const locations = props.locations
    //check if we have any Taps to show on the dashboard
    if (taps.length > 0 ) {
    //Here we take the array of JSON Objects (taps) and create an individual TapCard element for each one.
    const cards = taps.map(tap => {
        const beer = _.find(beers, {id: tap.TapBeerId}) || {name: 'UNKOWN', id: '-1', brewery: 'UNKNOWN'}
        return <div style={{ color: 'white' }}>
        <TapCard tap={tap} beerId={beer.id} beerName={beer.name} beerBrewery={beer.brewery} beerLogo={beer.logo}/>
        </div>
    })
    //Return all of the cards
    return <div style={{justifyContent: 'space-evenly', display: 'flex', marginTop: '1rem'}}>
    {cards}
    </div>
    //If there are no Taps in the table, display this message instead. 
    } else {
        return <ContentContainer>
        <h3 style={{ color: 'white' }}>Time for a refill!</h3>
        <p style={{ color: 'white' }}>Nothing is on tap right now!</p>
      </ContentContainer>
    }
}

export default Body