import React, { Component, useState, useEffect } from 'react';
import Amplify, { Auth, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { Card, Icon } from 'semantic-ui-react'
import SmoothieComponent, { TimeSeries } from 'react-smoothie';
import _ from 'lodash';

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'us-east-1',
  aws_pubsub_endpoint: 'wss://a3k1wmckzweko3-ats.iot.us-east-1.amazonaws.com/mqtt'
}));

/*
This is the new endpoint for Vegas

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'us-west-2',
  aws_pubsub_endpoint: 'wss://a3k1wmckzweko3-ats.iot.us-west-2.amazonaws.com/mqtt'
}));
*/


Amplify.Logger.LOG_LEVEL = 'DEBUG';

const SMOOTHIE_OPTIONS = {
};

var liveData = {
        sound: new TimeSeries(SMOOTHIE_OPTIONS),
        flow: new TimeSeries(SMOOTHIE_OPTIONS)
    };

function KegInfo(props) {
    const [temperature, setTemperature] = useState(0);
    const [id, setId] = useState(0);
    const [humidity, setHumidity] = useState(0);
    const [weight, setWeight] = useState(0);
    const [flow, setFlow] = useState(0);
    const [sound, setSound] = useState(0);
    const [online, setOnline] = useState(true);
    var time = new Date().getTime();
    const tapID = props.tap.id
    
  
    useEffect(() => {

      const onUpdateSensors = (data) => {
        console.log("onUpdateSensors: data received", data);
        var time = new Date().getTime();
      
        var minSound = 0;
        var soundRange = 100;
        var minFlow = 0;
        var flowRange = 100;
      
        if (data.value) {
          
          //Set Temp
          const temperature = _.get(data.value, `Channel.${tapID}.temperature.value`)
          //data.value.Channel.(tapID).ComponentName.(comp).value
          //if (temperature) ---> Do stuff
            if (data.value.temperature && data.value.temperature.value) {
              var fahrenheit = data.value.temperature.value * 9/5 + 32;
              setTemperature(fahrenheit);
            }
          //Set Humidity
          const humidity = _.get(data.value, `humidity.value`)
          if (!_.isUndefined(humidity)) {
                    setHumidity(humidity);
          }
          //Set Weight
          const weight = _.get(data.value, `weight.value`)
          console.log(weight)
          if (weight) {
                    setWeight(weight);
          }
          //Set Sound
          if (data.value.sound && data.value.sound.value) {
                    setSound(data.value.sound.value);
                  var adjustedSound = 100 * (parseFloat(data.value.sound.value) - minSound)/ soundRange;
                  liveData.sound.append(time, adjustedSound);
          }else {
                  liveData.sound.append(time, 0.0);
                }
          //Set Flow
          if (data.value.flow && data.value.flow.value) {
                  setFlow(data.value.flow.value);
                  var adjustedFlow = 100 * parseFloat((data.value.flow.value) - minFlow) / flowRange;
                  liveData.flow.append(time, adjustedFlow);
          }else {
                  liveData.flow.append(time, 0.0);
                }
          //Set Id
          if (data.value.id) {
                  setId(data.value.id);
          }
        }
      };
      
      const sensorTopics = [
        //This will be hardcoded to 'sbs/components' for reinvent 2021
        'sbs/SBS900/sensors',
      ];
      
      const sensorSub = PubSub.subscribe(sensorTopics).subscribe({
        next: onUpdateSensors,
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      
      return () => {
        sensorSub.unsubscribe();
      };
    }, [online, props.id]);
    
  return ( 
    <div style={{backgroundColor: 'black'}} className="temperature dataBox">
      <div>
      <span className="dataTitles" style={{ color: '#FFFFFF' }}>TEMP: </span>
      <span className="dataValues" style={{ color: '#FF0000' }}>{temperature.toFixed(1)}°F</span>
      </div>
      
      <div>
      <span className="dataTitles" style={{ color: '#FFFFFF' }}>SOUND: </span>
      <span className="dataValues" style={{ color: '#FF0000' }}>{sound}</span>
      <SmoothieComponent responsive
                        height={80}
                        minValue={0}
                        maxValue={100}
                        millisPerPixel={50}
                        maxValueScale={3.0}
                        minValueScale={3.0}
                        millisPerLine={400}
                        streamDelay={2000}
                        series={[
                            {
                                data: liveData.sound,
                                strokeStyle: { r: 0, g: 255, b: 0 },
                                fillStyle: { r: 0, g: 255, b: 0 },
                                lineWidth: 2
                            }
                        ]}
                    />
      </div>
      
      <div>
      <span className="dataTitles" style={{ color: '#FFFFFF' }}>HUMIDITY: </span>
      <span className="dataValues" style={{ color: '#FF0000' }}>{humidity}</span>
      </div>
      
      <div>
      <span className="dataTitles" style={{ color: '#FFFFFF' }}>WEIGHT: </span>
      <span className="dataValues" style={{ color: '#FF0000' }}>{weight}</span>
      </div>
      
      <div>
      <span className="dataTitles" style={{ color: '#FFFFFF' }}>FLOW: </span>
      <span className="dataValues" style={{ color: '#FF0000' }}>{flow}</span>
      <SmoothieComponent
                        minValue={0}
                        maxValue={100}
                        millisPerPixel={50}
                        maxValueScale={3.0}
                        minValueScale={3.0}
                        millisPerLine={400}
                        responsive
                        height={80}
                        streamDelay={2000}
                        series={[
                            {
                                data: liveData.flow,
                                strokeStyle: { r: 255, g: 153, b: 0 },
                                fillStyle: { r: 255, g: 153, b: 0 },
                                lineWidth: 2
                            }
                        ]}
                    />
      </div>
    </div>
  );   
}

export default KegInfo;