import React from 'react'
import { Hashtag } from 'react-twitter-widgets'


function Footer(props) {
    return <div style={{ color: 'white', padding: '3rem'}}>
        <Hashtag hashtag="simplebeerservice" />
    </div>
}

export default Footer