import logo from './logo.svg';
import './App.css';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'

import Dashboard from './pages/Dashboard';

Amplify.configure(awsExports);

function App() {
  return (
    <div style={{backgroundColor: 'black'}} className="App">
      <head >
        <title>SBS: Simple Beer Service</title>
      </head>
      <body>
        <Dashboard />
      </body>
    </div>
  );
}

export default App
