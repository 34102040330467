import React from 'react'
import { Card, Icon } from 'semantic-ui-react'
import KegInfo from '../components/KegInfo'

const extra = (
  
  <a>
    <Icon name='beer' />
    Now Serving!
  </a>
)

function BeerInfo(props) {
    //Here we have the beer ID, name, and brewery passed in
    const tap = props.tap
    const beerId = props.beerId
    const beerName = props.beerName
    const beerBrewery = props.beerBrewery
    const beerLogo = props.beerLogo
    //Return the html elements to display the info
    return <div>
      <Card centered
        
        image={beerLogo}
        header={beerName}
        meta={beerBrewery}
        description={beerId}
        extra=<KegInfo tap={tap}/>
      />
      
    </div>
}

export default BeerInfo