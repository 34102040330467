import React from 'react'
import {useState, useEffect} from 'react'
import Amplify, {PubSub} from 'aws-amplify'
import API, {graphqlOperation} from '@aws-amplify/api'
import {listTaps, listBeers, listLocations} from '/home/ec2-user/environment/sbs/src/graphql/queries'
import {Grid, Input, List, Segment, Card, Container, Table} from 'semantic-ui-react'
import {BrowserRouter as Router, Route, NavLink} from 'react-router-dom';
import { Auth } from 'aws-amplify'
import awsconfig from '../aws-exports';
import * as queries from '/home/ec2-user/environment/sbs/src/graphql/queries'
import {AWSIoTProvider} from '@aws-amplify/pubsub'
import aws_exports from '../aws-exports';
import Header from './Header'
import Body from './Body'
import Footer from './Footer'

Amplify.configure({ ...aws_exports, "aws_appsync_authenticationType": "AWS_IAM"});
API.configure(awsconfig);


//This function uses GraphQL to retreive the latest Taps and Beers in the DynamoDB tables. 
function Taps() {
  const[Taps, setTaps] = useState([])
  const[Beers, setBeers] = useState([])
  const[Locations, setLocations] = useState([])
  
  useEffect(() => {
    fetchTaps()
    fetchBeers()
    fetchLocations()
  }, [])
  //Get the list of Taps from DynamoDB
  const fetchTaps = async () => {
    try {
      const tapData = await API.graphql(graphqlOperation(listTaps))
      const Taps = tapData.data.listTaps.items
      setTaps(Taps)
      console.log(JSON.stringify(Taps))
    }
    catch (err) {
      setTaps({message: 'Graph QL Failed Loading Taps'})
      console.log('error fetching taps')
  }
}
//Get the list of Beers from DynamoDB
  const fetchBeers = async () => {
    try {
      const beerData = await API.graphql(graphqlOperation(listBeers))
      const Beers = beerData.data.listBeers.items
      setBeers(Beers)
    }
    catch (err) {
      setBeers({message: 'GraphQL Failed Loading Beers'})
      console.log('error fetching beers')
  }
}

  const fetchLocations = async() => {
    try {
      const locationData = await API.graphql(graphqlOperation(listLocations))
      const Locations = locationData.data.listLocations.items
      setLocations(Locations)
    }
    catch (err) {
      setLocations({message: 'GraphQL Failed loading locaitons'})
      console.log('error fetching locations')
    }
  }
  

//Here we are returning a Body element, which is passed in the list of taps and beers.
return (

   <Body taps={Taps} beers={Beers}/>

  )
};
//Render the dashboard of taps
function Dashboard(props) {
    return <div style={{ backgroundImage: "url(/images/bg.jpg)", backgroundSize: 'cover', backgroundRepeat: 'repeat' }}>
      <Header />
      <Taps />
      <Footer />
    </div>
}

export default Dashboard