import React from 'react'
import BeerInfo from '../components/BeerInfo'
import BeerMap from '../components/BeerMap'
import BeerFlow from '../components/BeerFlow'
import TapInfo from '../components/TapInfo'
import {
  Container,
  Segment,
  Grid,
  Button,
  Icon,
} from 'semantic-ui-react';


function TapCard(props) {
    //Here we pass in the tap object as well as its corresponding beer ID, name, and brewery
    const tap = props.tap
    const beerId = props.beerId
    const beerName = props.beerName
    const beerBrewery = props.beerBrewery
    const beerLogo = props.beerLogo
    //We return two elements: 1. A TapInfo element that tells us information about the tap itself, and 2. a BeerInfo element that tells us what beer is in that tap
    return<BeerInfo tap={tap} beerId={beerId} beerName={beerName} beerBrewery={beerBrewery} beerLogo={beerLogo}/>
}

export default TapCard